<template>
  <div class="home">
    <Main />
  </div>
</template>

<script>
import Main from '@/components/Main.vue'
export default {
  name: "Home",
  components: {
    Main
  },
};
</script>
